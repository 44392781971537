var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subpage",class:{ 'has-visual': _vm.hasVisual }},[_c('HeroDefault',{class:{ 'has-promo': _vm.promoLabel },attrs:{"content":{
      title: _vm.content.headline || _vm.content.title,
      subtitle: _vm.content.displayTitle,
      intro: _vm.content.intro,
      link: _vm.content.link,
      picture: _vm.content.picture,
      video: _vm.content.video,
      videoMobile: _vm.content.videoMobile,
      videoMobileFallback: _vm.content.videoMobileFallback,
      videoFallback: _vm.content.videoFallback,
    },"show-crumbs":false,"cover":_vm.content.isCover,"promoLabel":_vm.promoLabel}}),(_vm.content.flexibleContent)?_c('UiFlexible',{staticClass:"subpage__flexible mt-xl"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }